import DApp from '@components/DApp/DApp';
import DIcon from '@components/DIcon/DIcon';
import DMain from '@components/DMain/DMain';
import React from "react";

export default function PlaygroundPage () {
  return (
    <DApp>
      <DMain>
        <DIcon icon="chevron-right" className="test" />
      </DMain>
    </DApp>
  )
}